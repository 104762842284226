import React from "react";
import { Box, Heading } from "theme-ui";

import { graphql } from "gatsby";

import Layout from "components/Layout";
import MarkdownParser from "components/MarkdownParser";
import SEO from "components/SEO";

const PrivacyPage = ({ data: { config } }) => {
  return (
    <Layout>
      <Box
        sx={{
          mb: "auto",
        }}
      >
        <Heading
          sx={{
            my: [16, 20],
          }}
        >
          Privacy Policy
        </Heading>
        <MarkdownParser>
          {config.privacyPolicy?.text?.data?.text}
        </MarkdownParser>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query PrivacyPageQuery {
    config: strapiAppConfig {
      privacyPolicy: privacy_policy {
        text {
          data {
            text
          }
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Privacy Policy" />;

export default PrivacyPage;
